import { ErrorMessage, Formik } from "formik";
import { bookingContractFormElements } from "../../formElements/formElementsData";
import FormElement from "../../components/atoms/FormElement";
import Button from "../../components/atoms/Button";
import { contractMessageFormValidation } from "../../validation/validationSchema";
import {
  FormElementWrapper,
  Label,
  Textarea,
  Input,
} from "../../components/atoms/FormElement/StyledFormElement";
import {
  ContactForm,
  SendedInfo,
} from "../../components/forms/Contact/StyledContact";
import { useState } from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";
import contractPic from "../../assets/images/corporate-handshake-deal-stockcake.jpg"
import { ContractWrapper, ContractImage, RadioWrapper, FormWrapper } from "./BookWithContract";

const BookWithContract = () => {
  const [sended, setSended] = useState(false);

  return (
    <ContractWrapper>
      <ContractImage src={contractPic} />
      <Formik
        initialValues={{
          firstName: "",
          surname: "",
          companyName: "",
          email: "",
          phone: "",
          message: "",
          contractLength: 3,
          hoursType: "week",
          hours: "",
        }}
        validationSchema={contractMessageFormValidation}
        onSubmit={async (values, actions) => {
          await fetch("https://api.emailjs.com/api/v1.0/email/send", {
            method: "post",
            body: JSON.stringify({
              service_id: "service_u8hv6xv",
              template_id: "template_h0zqx5a",
              user_id: "Fjnkk9bShGnfoIUc8",
              template_params: values,
            }),
            headers: {
              "Content-type": "application/json",
            },
          });
          actions.resetForm();

          setSended(true);
          setTimeout(() => {
            setSended(false);
          }, 5000);
        }}
        enableReinitialize={true}
      >
        {(props) => (
          <FormWrapper>
          <form onSubmit={props.handleSubmit}>
            {sended && (
              <SendedInfo>
                <BsFillInfoCircleFill />
                Message sended succesfully!
              </SendedInfo>
            )}
            {bookingContractFormElements.map((element) => (
              <FormElement
                key={element.inputName}
                inputName={element.inputName}
                inputType={element.inputType}
                labelContent={element.labelContent}
              />
            ))}
            <FormElementWrapper>
              <RadioWrapper>
                <label>
                  <input
                    type="radio"
                    name="hoursType"
                    value="week"
                    onChange={props.handleChange}
                    checked={props.values.hoursType === "week"}
                  />{" "}
                  per week
                </label>
                <label>
                  <input
                    type="radio"
                    name="hoursType"
                    value="month"
                    onChange={props.handleChange}
                    checked={props.values.hoursType === "month"}
                  />{" "}
                  per month
                </label>
              </RadioWrapper>
            </FormElementWrapper>
            <FormElementWrapper>
              <Label>Contract length (in months)</Label>
              <Input
                type="number"
                min="3"
                name="contractLength"
                id="contractLength"
                value={props.values.contractLength}
                onChange={props.handleChange}
              />
              <div className="error-wrapper">
                <ErrorMessage name="contractLength" />
              </div>
            </FormElementWrapper>
            <FormElementWrapper>
              <Label>Message</Label>
              <Textarea
                rows={7}
                name="message"
                id="message"
                value={props.values.message}
                onChange={props.handleChange}
              ></Textarea>
              <div className="error-wrapper">
                <ErrorMessage name="message" />
              </div>
            </FormElementWrapper>

            <Button type="submit">Send</Button>
          </form>
            </FormWrapper>
        )}
      </Formik>
    </ContractWrapper>
  );
};

export default BookWithContract;
