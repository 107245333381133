import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { FaBasketShopping } from "react-icons/fa6";

export const MenuWrapper = styled.div`
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  background-color: #8f91a2;
  padding: 20px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1300px) {
   flex-direction: column;
    padding: 20px 50px;
    justify-content: center;
  }

  @media (max-width: 700px) {
    padding: 20px;
    flex-direction: column;
  }
`;

export const MenuNav = styled.nav`
  @media (max-width: 1100px) {
    order: 2;
    width: 100%;
  }

`;

export const MenuLinks = styled.ul`
  display: flex;
  gap: 20px;

  @media (max-width: 1100px) {
    order: 1;
    width: 100%;
    margin-bottom: 20px;
    justify-content: center;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const MenuLinkItem = styled.li`
  position: relative;
`;

export const MenuLink = styled(NavLink)`
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  color: #3c3744;

  &:hover {
    font-weight: bold !important;
    font-size: 22px !important;
  }
  &.active {
    color: white;
    /* color: #4f9b5a; */
    font-weight: bold !important;
    font-size: 22px !important;
  }
`;

export const BasketLink = styled(NavLink)`
  text-decoration: none;
  display: flex;
  font-size: 25px;
  color: #fff;
  align-items: center;
  gap: 10px;

  & p {
    color: #000;
    font-size: 16px;

    &:hover {
      font-weight: bold;
    }
  }

  &.active p {
    color: #fff;
    font-weight: bold;
  }
`;

export const AuthButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 1300px) {
    margin-top: 20px;
  }

  @media (max-width: 1100px) {
    margin: 0 auto;
  }
    

  @media (max-width: 700px) {
    margin-top: 20px;
  }

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

export const SubMenuWrapper = styled.ul`
  position: absolute;
  background: transparent;
  top: 35px;
  width: 150px;
  z-index: 999999;
`;

export const SubMenuItem = styled.li`
  background: transparent;
  margin-bottom: 5px;
`;

export const SubMenuLink = styled(NavLink)`
  text-decoration: none;
    padding: 10px;
  font-size: 15px;
  color: #fff;
  background: #4f9b5a;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
`;
