import { MemberImage, MemberItem, MemberList, MemberListItem, MemberListItemIcon, MemberListItemText, MembersWrapper, MemberTitle } from "./StyledMembers";

import BKandPAYImage from "../../assets/images/BK_PAY.jpg";
import PAandAdminImage from "../../assets/images/PA_Admin.jpg";
import AdHocImage from "../../assets/images/diary.jpg";
import homeImage from "../../assets/images/homepage_background.jpg";
import itSupport from "../../assets/images/it_support.jpg";
import bookkeeping from "../../assets/images/bookkiping.jpg";

import member1 from "../../assets/images/member1.jpg";
import member2 from "../../assets/images/member2.jpg";
import member3 from "../../assets/images/member3.jpg";
import member4 from "../../assets/images/member4.jpg";
import member5 from "../../assets/images/member5.jpg";
import PAImage from "../../assets/images/PA.jpg";



const Members = () => {
    return (
<MembersWrapper>
      <MemberItem>
        <MemberImage>
          <img src={PAImage} />
        </MemberImage>
        <div>
          <MemberTitle>Marzena Rybicka</MemberTitle>
          <MemberList>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>Managing Director</MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>Marzena has 16 years of experience in accountancy field.</MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>Prepares VAT returns and accounts for limited companies, sole traders & partnerships and CIS returns.</MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
              In-depth understanding of Sage, Xero, QuickBooks, and Iris packages.
              </MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Marzena has been our Payroll Manager since 2010 and manages a team of four.
              </MemberListItemText>
            </MemberListItem><MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Responsible for running weekly, fortnightly, monthly and annual payrolls for clients, including sole traders, carers, partnerships, limited companies and charities.
              </MemberListItemText>
            </MemberListItem><MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Ensures clients are confident with their payroll calculations and is happy to discuss queries over the phone or in person.              </MemberListItemText>
            </MemberListItem><MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
              Manages auto-enrolment workplace pensions, submitting declarations with The Pensions Regulator, liaising with HMRC, setting up new PAYE schemes, CIS, Statutory Sick Pay, Maternity/Paternity Pay and assisting clients with BACS payments.
              </MemberListItemText>
            </MemberListItem><MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
              Marzena takes pride in providing the best possible payroll service for our clients.
              </MemberListItemText>
            </MemberListItem>
          </MemberList>
        </div>
      </MemberItem>
      <MemberItem>
        <MemberImage>
          <img src={member1} />
        </MemberImage>
        <div>
          <MemberTitle>Meryl Rose</MemberTitle>
          <MemberList>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Junior Bookkeeper
              </MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Payslips and reports emailed to you for acceptance
              </MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                RTI submissions made directly to HMRC
              </MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Starters and leavers including P45
              </MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Annual holiday and duvet day entitlements
              </MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Pensions (auto enrolment)
              </MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Statutory payments for sickness and parenting pay
              </MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>Legal deductions</MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Salary sacrifice schemes
              </MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>CIS returns </MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                P11s, P11Ds, P32s and year end submission
              </MemberListItemText>
            </MemberListItem>
          </MemberList>
        </div>
      </MemberItem>
      <MemberItem>
        <MemberImage>
          <img src={member2} />
        </MemberImage>
        <div>
          <MemberTitle>PA & Administration</MemberTitle>
          <MemberList>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Email and diary management
              </MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Website and blog updates
              </MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>Document formatting</MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>Project Management</MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>Sales Support</MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Email and diary management
              </MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>General admin </MemberListItemText>
            </MemberListItem>
          </MemberList>
        </div>
      </MemberItem>
      <MemberItem>
        <MemberImage>
          <img src={member3} />
        </MemberImage>
        <div>
          <MemberTitle>HR Management</MemberTitle>
          <MemberList>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>Recruitment</MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>HR Management</MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Dedicated support and coordination
              </MemberListItemText>
            </MemberListItem>
          </MemberList>
        </div>
      </MemberItem>
      <MemberItem>
        <MemberImage>
          <img src={member5} />
        </MemberImage>
        <div>
          <MemberTitle>IT Support</MemberTitle>
          <MemberList>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Software development (React JS)
              </MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>Websites</MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Database and CRM management
              </MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Microsoft Office - Excel
              </MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                PowerPoint presentations
              </MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>Canva designs and edits</MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>Image editing</MemberListItemText>
            </MemberListItem>

            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>Systems setup</MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Systems setup and customer support
              </MemberListItemText>
            </MemberListItem>
          </MemberList>
        </div>
      </MemberItem>
      <MemberItem>
        <MemberImage>
          <img src={member4} />
        </MemberImage>
        <div>
          <MemberTitle>Ad-hoc support</MemberTitle>
          <MemberList>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>Social Media Management</MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Event planning and support
              </MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Travel planning and itineraries
              </MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>Email marketing</MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>Social media support</MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>Personal support</MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Diary, email and calendar management
              </MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Spreadsheet creation and updating
              </MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>Research and reports</MemberListItemText>
            </MemberListItem>
            <MemberListItem>
              <MemberListItemIcon />
              <MemberListItemText>
                Meeting arrangements and reservations
              </MemberListItemText>
            </MemberListItem>
          </MemberList>
        </div>
      </MemberItem>
    </MembersWrapper>
    )
}

export default Members;

