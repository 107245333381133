import {styled} from 'styled-components'

export const BookingHoursWrapper = styled.div`
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    margin-top: 20px;
    gap: 20px;

    @media(max-width: 900px){
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    
`

export const BookingHoursImage = styled.img`
    width: 100%;
    height: 500px;
    object-fit: cover;
    @media(max-width: 900px){
        order: 2;
    }
`

export const BookingHoursForm = styled.div`
    margin-left: 100px;

    @media(max-width: 900px){
        order: 1;
        margin-left: 0;
    }
`

export const BookingFormField = styled.div`
    margin-bottom: 20px;
`

export const BookingFormLabel = styled.label`
    margin-bottom: 10px;
    display: block;
`

export const BookingFormInput = styled.input`
    padding: 5px;
    width: 200px;
    text-align: center;
`