import { DataGrid } from "@mui/x-data-grid";
import { useUser } from "../../contexts/User";
import { useEffect, useState } from "react";
import { where, query, getDocs } from "firebase/firestore";
import { bookingCollection, paymentsCollection } from "../../firebase";
import { ReservationInterface } from "../BookService";
import { BookingHistoryWrapper } from "./StyledBookingHistory";
import { Booking } from "../../components/atoms/BookingCalendar";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FLEX_PRICE } from "../../data/prices";
import { Link } from "react-router-dom";
import { LiaFileInvoiceSolid } from "react-icons/lia";

const getNumericHour = (hour: string) =>
  !hour ? 0 : parseInt(hour.split(":").slice(0, 1)[0]);

const BookingHistory = () => {
  const { user } = useUser();

  const [reservations, setReservations] = useState<Booking[]>([]);

  const getReservations = async () => {
    const reservationsSnapshot = await getDocs(
      query(
        bookingCollection,
        user?.id === "GFLkhtp4KNM0l8AXoEee"
          ? where("user_id", "!=", "-1")
          : where("user_id", "==", user?.id)
      )
    );

    const paymentsSnapshot = await getDocs(
      query(
        paymentsCollection,
        user?.id === "GFLkhtp4KNM0l8AXoEee"
          ? where("user_id", "!=", "-1")
          : where("user_id", "==", user?.id),
        where("flexible_hours", ">", 0)
      )
    );

    const history: Booking[] = [];
    reservationsSnapshot.docs.forEach((booking) =>
      history.push(booking.data() as Booking)
    );
    paymentsSnapshot.docs.forEach((payment) =>
      history.push(payment.data() as Booking)
    );


    setReservations(history);
  };

  useEffect(() => {
    getReservations();
  }, []);

  return (
    <BookingHistoryWrapper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {user?.id === "GFLkhtp4KNM0l8AXoEee" && (
                <TableCell>User</TableCell>
              )}
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Show invoice</TableCell>
              <TableCell>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reservations
              .sort(
                (a, b) =>
                  new Date(`${b.date} ${b.start_time}`).getTime() -
                  new Date(`${a.date} ${a.start_time}`).getTime()
              )
              .map((reservation) => (
                <TableRow>
                  {user?.id === "GFLkhtp4KNM0l8AXoEee" && (
                    <TableCell>
                      {reservation.firstName} {reservation.surname}{" "}
                      {reservation.companyName
                        ? " - " + reservation.companyName
                        : ""}
                    </TableCell>
                  )}
                  <TableCell>
                    {reservation.flexible_hours ? (
                      "Flexible"
                    ) : (
                      <>{reservation.date}</>
                    )}
                  </TableCell>
                  <TableCell>
                    {reservation.flexible_hours ? (
                      "Flexible"
                    ) : (
                      <>
                        {getNumericHour(reservation.start_time) > 12
                          ? getNumericHour(reservation.start_time) - 12
                          : getNumericHour(reservation.start_time)}
                        :00{" "}
                        {getNumericHour(reservation.start_time) < 12
                          ? "am"
                          : "pm"}
                        {" - "}
                        {getNumericHour(reservation.end_time) > 12
                          ? getNumericHour(reservation.end_time) - 12
                          : getNumericHour(reservation.end_time)}
                        :00{" "}
                        {getNumericHour(reservation.end_time) < 12
                          ? "am"
                          : "pm"}
                      </>
                    )}
                  </TableCell>
                 
                  <TableCell>
                    £{" "}
                    {reservation.flexible_hours
                      ? FLEX_PRICE * reservation.flexible_hours
                      : reservation.price}
                  </TableCell>
                  <TableCell>
                  <Link
                      style={{ color: "#2e7d32", fontSize: "30px" }}
                      to={`/payments-history/invoice/${reservation.payment_id}`}
                    >
                      <LiaFileInvoiceSolid />
                    </Link>
                  </TableCell>
                  <TableCell>
                    {reservation.type ? reservation.type : "Flexible"}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </BookingHistoryWrapper>
  );
};

export default BookingHistory;
