import styled from "styled-components";
import { ImCheckboxChecked } from "react-icons/im";

export const ServicesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  padding: 20px 100px;
  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 600px) {
    padding: 20px;
  }
`;

export const ServiceItem = styled.div`
  display: grid;
  grid-template-columns: 3fr 7fr;
  gap: 40px;
  background-color: #f8f6f6;
  padding: 20px;
  border-radius: 15px;
`;

export const ServiceImage = styled.div`
  img {
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 50%;
  }
`;

export const ServiceTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
`;

export const ServiceList = styled.ul`
  margin-top: 20px;
`;

export const ServiceListItem = styled.li`
  margin-bottom: 15px;
  display: flex;
  gap: 10px;
`;

export const ServiceListItemIcon = styled(ImCheckboxChecked)`
  color: #8f91a2;
  width: 20px;
`;

export const ServiceListItemText = styled.p`
  width: calc(100% - 20px);
`;
