import { LoggedBookServiceWrapper } from "./StyledLoogedBookService";
import BookingCalendar from "../../components/atoms/BookingCalendar";
import { FormControlLabel, Switch } from "@mui/material";
import { useState } from "react";
import BookingHours from "../../components/atoms/BookingHours";

interface Reservation {
  title: string;
  start: Date;
  end: Date;
  user_id: string | undefined;
}

const LoggedBookService = () => {
  const [calendarView, setCalendarView] = useState(true);

  return (
    <LoggedBookServiceWrapper>
      <FormControlLabel
        value={calendarView}
        onChange={(e) =>
          setCalendarView((e.target as HTMLInputElement).checked)
        }
        control={<Switch defaultChecked />}
        label={calendarView ? "Click if you want to change to flexiable hours" : "Click if you want to change to Exact time"}
      />
      {calendarView && <BookingCalendar logged={true} />}
      {!calendarView && <BookingHours logged={true} />}
    </LoggedBookServiceWrapper>
  );
};

export default LoggedBookService;
