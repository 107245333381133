import { SyntheticEvent, useState } from "react";
import Button from "../Button";
import { useBasket } from "../../../contexts/Basket";
import flexibleHoursPic from "../../../assets/images/work-from-anywhere-stockcake.jpg"
import { BookingHoursWrapper, BookingHoursImage, BookingHoursForm, BookingFormField, BookingFormLabel, BookingFormInput } from "./BookingHoursStyled";

interface IProps {
  logged: boolean;
}

const BookingHours = ({ logged }: IProps) => {
  const [hoursInput, setHoursInput] = useState(1);
  const [dateInput, setDateInput] = useState('');

  const { addFlexibleHourToBasket } = useBasket();

  const handleInputChange = (e: SyntheticEvent) => {
    const value = (e.target as HTMLInputElement).value.replace(/[^\d]/, "");
    const parsedValue = parseInt(value);

    if (isNaN(parsedValue) || (!isNaN(parsedValue) && parsedValue < 1)) {
      setHoursInput(1);
    } else {
      if (parsedValue > 100) {
        setHoursInput(100);
      } else {
        setHoursInput(parsedValue);
      }
    }
  };

  const bookHours = () => {
    addFlexibleHourToBasket(hoursInput, dateInput);
    setHoursInput(1);
  };

  return (
    <BookingHoursWrapper>
      <BookingHoursImage src={flexibleHoursPic} />
      <BookingHoursForm>
          <div>
            <BookingFormField>
              <BookingFormLabel>How many hours do you require?</BookingFormLabel>
              <BookingFormInput type="number" value={hoursInput} onChange={handleInputChange} />
            </BookingFormField>
            <BookingFormField>
              <BookingFormLabel>Deadline to complete your task</BookingFormLabel>
              <BookingFormInput type="date" value={dateInput} onChange={(e) => setDateInput(e.target.value)} />
            </BookingFormField>
            <Button onClick={() => bookHours()}>Book</Button>
        </div>
      </BookingHoursForm>
    </BookingHoursWrapper>
    
  );
};

export default BookingHours;
