import { adminBookingFormElement } from "../../components/atoms/formElements/formElementsData";
import { Input, Label } from "../Basket/StyledBasket";
import { ProfileWrapper, ProfileHeader } from "./StyledProfile";
import { useUser } from "../../contexts/User";
import { useState } from "react";
import Button from "../../components/atoms/Button";
import { doc, setDoc } from "firebase/firestore";
import { firestore, auth } from "../../firebase";
import { updateEmail, updatePassword } from "firebase/auth";

const Profile = () => {
  const { user } = useUser();

  const [userProfile, setUserProfile] = useState<any>({
    firstName: user ? user.firstName : "",
    surname: user ? user.surname : "",
    contactNumber: user ? user.contactNumber : "",
    companyName: user ? user.companyName : "",
    town: user ? user.town : "",
    postcode: user ? user.postcode : "",
    street: user ? user.street : "",
  });

  const [userEmail, setUserEmail] = useState<any>({
    old: user ? user.email : "",
    new: "",
    repeat_new: "",
  });

  const [userPassword, setUserPassword] = useState<any>({
    password: "",
    repeat_password: "",
  });

  const validateProfileUserInfo = () => {
    for (let i = 0; i < adminBookingFormElement.length; i++) {
      if (
        adminBookingFormElement[i].inputName === "companyName" ||
        adminBookingFormElement[i].inputName === "email"
      ) {
        continue;
      }
      if (!userProfile[adminBookingFormElement[i].inputName]) {
        return false;
      }
    }
    return true;
  };

  const saveUserProfile = async () => {
    if (!validateProfileUserInfo()) {
      return;
    }

    if (auth.currentUser) {
      await setDoc(doc(firestore, "users", user ? user.id : ""), userProfile, {
        merge: true,
      });
    }
  };

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const checkLoginTime = () => {
    const lastLoginDate = new Date(
      auth.currentUser?.metadata.lastSignInTime + ""
    );
    lastLoginDate.setMinutes(lastLoginDate.getMinutes() + 10);

    if (lastLoginDate.getTime() < new Date().getTime()) {
      return false;
    }
    return true;
  };

  const updateUserEmail = async () => {
    if (!checkLoginTime()) {
      alert("Wyloguj sie");
      return;
    }

    if (
      !validateEmail(userEmail.new) ||
      userEmail.new !== userEmail.repeat_new
    ) {
      alert("Email niepoprawny");
      return;
    }

    if (auth.currentUser) {
      await updateEmail(auth.currentUser, userEmail.new);
      await setDoc(
        doc(firestore, "users", user ? user.id : ""),
        { email: userEmail.new },
        {
          merge: true,
        }
      );

      setUserEmail({
        old: userEmail.new,
        new: "",
        repeat_new: "",
      });
    }
  };

  const updateUserPassword = async () => {
    if (!checkLoginTime()) {
      alert("Wyloguj sie");
      return;
    }

    if (
      userPassword.password.length < 6 ||
      userPassword.password !== userPassword.repeat_password
    ) {
      return alert("Zle haslo");
    }

    if (auth.currentUser) {
      await updatePassword(auth.currentUser, userPassword.password);
      setUserPassword({
        password: "",
        repeat_password: "",
      });
    }
  };

  return (
    <ProfileWrapper>
      <ProfileHeader>Your data</ProfileHeader>
      <div style={{ marginBottom: "40px" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          {adminBookingFormElement
            .filter((el) => el.inputName !== "email")
            .map((el, index) => (
              <div>
                <Label>{el.labelContent}</Label>
                <Input
                  key={index}
                  name={el.inputName}
                  type={el.inputType}
                  value={userProfile[el.inputName]}
                  onChange={(e) =>
                    setUserProfile((prev: any) => ({
                      ...prev,
                      [el.inputName]: e.target.value,
                    }))
                  }
                />
              </div>
            ))}
        </div>
        <Button onClick={() => saveUserProfile()}>Save</Button>
      </div>

      <ProfileHeader>Change email</ProfileHeader>
      <div style={{ marginBottom: "40px" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          <div>
            <Label>Current email</Label>
            <Input
              disabled={true}
              type="email"
              value={userEmail.old}
              onChange={(e) =>
                setUserEmail((prev: any) => ({
                  ...prev,
                  old: e.target.value,
                }))
              }
            />
          </div>
          <div>
            <Label>New email</Label>
            <Input
              type="email"
              value={userEmail.new}
              onChange={(e) =>
                setUserEmail((prev: any) => ({
                  ...prev,
                  new: e.target.value,
                }))
              }
            />
          </div>
          <div>
            <Label>Repeat new email</Label>
            <Input
              type="email"
              value={userEmail.repeat_new}
              onChange={(e) =>
                setUserEmail((prev: any) => ({
                  ...prev,
                  repeat_new: e.target.value,
                }))
              }
            />
          </div>
        </div>
        <Button onClick={() => updateUserEmail()}>Save</Button>
      </div>

      <ProfileHeader>Change password</ProfileHeader>
      <div style={{ marginBottom: "40px" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          <div>
            <Label>New password</Label>
            <Input
              type="password"
              value={userPassword.password}
              onChange={(e) =>
                setUserPassword((prev: any) => ({
                  ...prev,
                  password: e.target.value,
                }))
              }
            />
          </div>
          <div>
            <Label>Repeat new password</Label>
            <Input
              type="password"
              value={userPassword.repeat_password}
              onChange={(e) =>
                setUserPassword((prev: any) => ({
                  ...prev,
                  repeat_password: e.target.value,
                }))
              }
            />
          </div>
        </div>
        <Button onClick={() => updateUserPassword()}>Save</Button>
      </div>
    </ProfileWrapper>
  );
};

export default Profile;
