import { useUser } from "../../contexts/User";
import { useEffect, useState } from "react";
import { where, query, getDocs, setDoc, doc } from "firebase/firestore";
import { firestore, paymentsCollection } from "../../firebase";

import { PaymentsHistoryWrapper } from "./StyledPaymentsHistory";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "../../components/atoms/Button";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { Link } from "react-router-dom";

interface Payment {
  id: string;
  amount: number;
  firstName: string;
  surname: string;
  status: string;
  user_id: string;
  companyName: string;
  date: {
    seconds: number;
    nanoseconds: number;
  };
}

const getNumericHour = (hour: string) =>
  parseInt(hour.split(":").slice(0, 1)[0]);

const PaymentsHistory = () => {
  const { user } = useUser();

  const [payments, setPayments] = useState<Payment[]>([]);

  const getPayments = async () => {
    const paymentsSnapshot = await getDocs(
      query(
        paymentsCollection,
        user?.id === "GFLkhtp4KNM0l8AXoEee"
          ? where("user_id", "!=", "-1")
          : where("user_id", "==", user?.id)
      )
    );

    setPayments(
      paymentsSnapshot.docs
        .map((payment) => ({ id: payment.id, ...payment.data() } as Payment))
        .sort((a, b) => b.date.seconds - a.date.seconds)
    );
  };

  useEffect(() => {
    getPayments();
  }, []);

  const setPaymentAsPayed = async (id: string) => {
    await setDoc(
      doc(firestore, "payments", id),
      { status: "success" },
      { merge: true }
    );

    window.location.reload();
  };


  return (
    <PaymentsHistoryWrapper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Recipient of an invoice</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Show invoice</TableCell>
              <TableCell>Payment Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map((payment) => (
              <TableRow>
                <TableCell>
                  <b>
                    {new Date(payment.date.seconds * 1000).toLocaleString()}
                  </b>
                </TableCell>
                <TableCell>
                  {payment.firstName} {payment.surname}{" "}
                  {payment.companyName ? " - " + payment.companyName : ""}
                </TableCell>
                <TableCell>
                  <b>£ {payment.amount}</b>
                </TableCell>
                <TableCell>
                  {payment.status === "manual" ||
                  payment.status === "in_progress" ||
                  payment.status === "failed" ? (
                    "-"
                  ) : (
                    <Link
                      style={{ color: "#2e7d32", fontSize: "30px" }}
                      to={`/payments-history/invoice/${payment.id}`}
                    >
                      <LiaFileInvoiceSolid />
                    </Link>
                  )}
                </TableCell>
                <TableCell>
                  {payment.status === "in_progress" && (
                    <Alert severity="info">In progress</Alert>
                  )}
                  {payment.status === "success" && (
                    <Alert severity="success">Succesfull</Alert>
                  )}
                  {payment.status === "failed" && (
                    <Alert severity="error">Unsuccesfull</Alert>
                  )}
                  {payment.status === "manual" && (
                    <Button onClick={() => setPaymentAsPayed(payment.id)}>
                      Mark as paid
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PaymentsHistoryWrapper>
  );
};

export default PaymentsHistory;
