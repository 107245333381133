import { SubMenuItem, SubMenuLink, SubMenuWrapper } from "./StyledMenu";
import { TfiFile, TfiFiles } from "react-icons/tfi";

const SubMenu = ({
  isVisibleBookingSubmenu,
  setIsVisibleBookingSubmenu,
  bookingLink,
}: {
  isVisibleBookingSubmenu: boolean;
  setIsVisibleBookingSubmenu: React.Dispatch<React.SetStateAction<boolean>>;
  bookingLink: string;
}) => {
  if (!isVisibleBookingSubmenu) {
    return null;
  }

  return (
    <SubMenuWrapper>
      <SubMenuItem>
        <SubMenuLink
          to={bookingLink}
          onClick={(e) => setIsVisibleBookingSubmenu(false)}
        >
          <TfiFile />
          Pay as you go
          
        </SubMenuLink>
      </SubMenuItem>
      <SubMenuItem>
        <SubMenuLink
          to="/book-service-with-contract"
          onClick={(e) => setIsVisibleBookingSubmenu(false)}
        >
     
        <TfiFiles />
          Contract
        </SubMenuLink>
      </SubMenuItem>
    </SubMenuWrapper>
  );
};

export default SubMenu;
