import styled from "styled-components";
import { ImCheckboxChecked } from "react-icons/im";

export const MembersWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  padding: 20px 100px;
  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 600px) {
    padding: 20px;
  }
`;

export const MemberItem = styled.div`
  display: grid;
  grid-template-columns: 3fr 7fr;
  gap: 40px;
  background-color: #f8f6f6;
  padding: 20px;
  border-radius: 15px;
`;

export const MemberImage = styled.div`
  img {
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 50%;
  }
`;

export const MemberTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
`;

export const MemberList = styled.ul`
  margin-top: 20px;
`;

export const MemberListItem = styled.li`
  margin-bottom: 15px;
  display: flex;
  gap: 10px;
`;

export const MemberListItemIcon = styled(ImCheckboxChecked)`
  color: #8f91a2;
  width: 20px;
`;

export const MemberListItemText = styled.p`
  width: calc(100% - 20px);
  line-height: 23px;
`;
