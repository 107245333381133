import { useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { unloggedRouter, loggedRouter } from "./router";
import { User, onAuthStateChanged } from "firebase/auth";
import { auth, usersCollection } from "../firebase";
import { getDocs, query, where } from "firebase/firestore";
import { useUser } from "../contexts/User";

const RouterWrapper = () => {
  const [currentRouter, setCurrentRouter] = useState(unloggedRouter);
  const { login, logout, isLogged } = useUser();

  const sleep = () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve("");
      }, 500);
    });

  const getUserFromDB = async (firebaseUser: User): Promise<any> => {
    const userSnapshot = await getDocs(
      query(usersCollection, where("id", "==", firebaseUser.uid))
    );


    if (!userSnapshot.docs || (userSnapshot.docs && !userSnapshot.docs[0])) {
      await sleep();
      return getUserFromDB(firebaseUser);
    }

    return userSnapshot;
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        // const userSnapshot = await getDocs(
        //   query(usersCollection, where("id", "==", firebaseUser.uid))
        // );

        const userSnapshot = await getUserFromDB(firebaseUser);

        login({ ...userSnapshot.docs[0].data(), id: userSnapshot.docs[0].id });
        setCurrentRouter(loggedRouter);
      } else {
        if (isLogged) {
          logout();
        }

        setCurrentRouter(unloggedRouter);
      }
    });
  }, []);

  return <RouterProvider router={currentRouter} />;
};

export default RouterWrapper;
