import { ThemeProvider } from "styled-components";
import { mainTheme } from "./globalStyles/Themes/mainTheme";
import ModalWrapper from "./components/ModalWrapper";
import ModalProvider from "./contexts/Modal";
import UserProvider from "./contexts/User";
import RouterWrapper from "./router/RouterWrapper";
import GlobalStyles from "./globalStyles/globalStyles";
import BasketContextProvider from "./contexts/Basket";

function App() {
  return (
    <UserProvider>
      <ModalProvider>
        <BasketContextProvider>
          <ThemeProvider theme={mainTheme}>
            <GlobalStyles />
            <RouterWrapper />
            <ModalWrapper />
          </ThemeProvider>
        </BasketContextProvider>
      </ModalProvider>
    </UserProvider>
  );
}

export default App;
