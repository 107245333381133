import styled from 'styled-components';

export const ContractWrapper = styled.div`
    padding: 100px;
    display: flex;
    gap: 100px;

    @media(max-width: 1000px){
        flex-direction: column;
        align-items: center;
    }

    @media(max-width: 600px){
        padding: 100px 20px;
    }
`

export const ContractImage = styled.img`
    width: 500px;
    height: 750px;
    object-fit: cover;
    max-width: 100%;

    @media(max-width: 1000px){
        order: 2;
    }
`
export const FormWrapper = styled.div`
    @media(max-width: 1000px){
        order: 1;
    }
`

export const RadioWrapper = styled.div`
    margin-bottom: 10px;
    display: flex;
    gap: 20px;
`