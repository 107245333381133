import {
  MenuWrapper,
  MenuLinks,
  MenuLinkItem,
  MenuLink,
  AuthButtonsWrapper,
  MenuNav,
  SubMenuWrapper,
  SubMenuItem,
  SubMenuLink,
} from "./StyledMenu";
import Button from "../atoms/Button";
import { useModal } from "../../contexts/Modal";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import SubMenu from "./SubMenu";

const UnloggedMenu = ({
  isVisibleBookingSubmenu,
  setIsVisibleBookingSubmenu,
  handleBookingMenuClick,
}: {
  isVisibleBookingSubmenu: boolean;
  setIsVisibleBookingSubmenu: React.Dispatch<React.SetStateAction<boolean>>;
  handleBookingMenuClick: (e: any) => void;
}) => {
  const { openModal } = useModal();

  return (
    <MenuWrapper>
      <MenuNav>
        <MenuLinks>
          <MenuLinkItem>
            <MenuLink to="/" onClick={(e) => setIsVisibleBookingSubmenu(false)}>
              Home
            </MenuLink>
          </MenuLinkItem>
          <MenuLinkItem>
            <MenuLink
              to="/services"
              onClick={(e) => setIsVisibleBookingSubmenu(false)}
            >
              Services
            </MenuLink>
          </MenuLinkItem>
          <MenuLinkItem>
            <MenuLink
              to="/pricing"
              onClick={(e) => setIsVisibleBookingSubmenu(false)}
            >
              Pricing
            </MenuLink>
          </MenuLinkItem>
          <MenuLinkItem>
            <MenuLink
              to="/members"
              onClick={(e) => setIsVisibleBookingSubmenu(false)}
            >
              Members
            </MenuLink>
          </MenuLinkItem>
          <MenuLinkItem>
            <MenuLink
              to="/book-service"
              onClick={handleBookingMenuClick}
              style={{
                fontWeight: isVisibleBookingSubmenu ? "bold" : "normal",
                fontSize: isVisibleBookingSubmenu ? "22px" : "20px",
              }}
            >
              Book service
            </MenuLink>
            <SubMenu
              isVisibleBookingSubmenu={isVisibleBookingSubmenu}
              setIsVisibleBookingSubmenu={setIsVisibleBookingSubmenu}
              bookingLink="/book-service"
            />
          </MenuLinkItem>
          <MenuLinkItem>
            <MenuLink
              to="/contact-us"
              onClick={(e) => setIsVisibleBookingSubmenu(false)}
            >
              Contact
            </MenuLink>
          </MenuLinkItem>
        </MenuLinks>
      </MenuNav>
      <AuthButtonsWrapper>
        <Button onClick={() => openModal("login")}>Login</Button>
        <Button onClick={() => openModal("register")}>Register</Button>
      </AuthButtonsWrapper>
    </MenuWrapper>
  );
};

export default UnloggedMenu;
