import {
  MenuWrapper,
  MenuLinks,
  MenuLinkItem,
  MenuLink,
  AuthButtonsWrapper,
  BasketLink,
} from "./StyledMenu";
import Button from "../atoms/Button";
import { useUser } from "../../contexts/User";
import { FaBasketShopping } from "react-icons/fa6";
import { useBasket } from "../../contexts/Basket";
import { Badge } from "@mui/material";
import { IoPerson } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import SubMenu from "./SubMenu";

const LoggedMenu = ({
  isVisibleBookingSubmenu,
  setIsVisibleBookingSubmenu,
  handleBookingMenuClick,
}: {
  isVisibleBookingSubmenu: boolean;
  setIsVisibleBookingSubmenu: React.Dispatch<React.SetStateAction<boolean>>;
  handleBookingMenuClick: (e: any) => void;
}) => {
  const { logout } = useUser();

  const { hours } = useBasket();

  return (
    <MenuWrapper>
      <nav>
        <MenuLinks>
          <MenuLinkItem>
            <MenuLink
              to="/"
              onClick={handleBookingMenuClick}
              style={{
                fontWeight: isVisibleBookingSubmenu ? "bold" : "normal",
                fontSize: isVisibleBookingSubmenu ? "22px" : "20px",
              }}
            >
              Book service
            </MenuLink>
            <SubMenu
              isVisibleBookingSubmenu={isVisibleBookingSubmenu}
              setIsVisibleBookingSubmenu={setIsVisibleBookingSubmenu}
              bookingLink="/"
            />
          </MenuLinkItem>
          <MenuLinkItem>
            <MenuLink
              to="/services"
              onClick={(e) => setIsVisibleBookingSubmenu(false)}
            >
              Services
            </MenuLink>
          </MenuLinkItem>
          <MenuLinkItem>
            <MenuLink
              to="/pricing"
              onClick={(e) => setIsVisibleBookingSubmenu(false)}
            >
              Pricing
            </MenuLink>
          </MenuLinkItem>
          <MenuLinkItem>
            <MenuLink
              to="/members"
              onClick={(e) => setIsVisibleBookingSubmenu(false)}
            >
              Members
            </MenuLink>
          </MenuLinkItem>
          <MenuLinkItem>
            <MenuLink
              to="/booking-history"
              onClick={(e) => setIsVisibleBookingSubmenu(false)}
            >
              History
            </MenuLink>
          </MenuLinkItem>
          <MenuLinkItem>
            <MenuLink
              to="/payments-history"
              onClick={(e) => setIsVisibleBookingSubmenu(false)}
            >
              Payments
            </MenuLink>
          </MenuLinkItem>
          <MenuLinkItem>
            <MenuLink
              to="/contact-us"
              onClick={(e) => setIsVisibleBookingSubmenu(false)}
            >
              Contact
            </MenuLink>
          </MenuLinkItem>
        </MenuLinks>
      </nav>
      <AuthButtonsWrapper>
        <BasketLink to="/basket">
          <Badge badgeContent={hours.length} color="primary">
            <FaBasketShopping color="action" />
          </Badge>
          <p>Basket</p>
        </BasketLink>
        <BasketLink to="/profile" title="My profile">
          <IoPerson />
        </BasketLink>
        <Button onClick={() => logout()}>Logout</Button>
      </AuthButtonsWrapper>
    </MenuWrapper>
  );
};

export default LoggedMenu;
