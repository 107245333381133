import {
  WeekDaysWrapper,
  CalendarDaysWrapper,
  DayWrapper,
  UnavailableDayWrapper,
  MonthsButtonsWrapper,
  MonthName,
  AvailableHoursWrapper,
  AvailableHoursData,
  AvailableHoursIcon,
  DayOfMonth,
  CalendarWrapper,
  ExcludeButton,
  EmployeeSelect,
  IncludeButton
} from "./StyledCalendar";
import { useState, useEffect } from "react";

import { useModal } from "../../../contexts/Modal";
import Button from "../Button";
import {where, getDocs, addDoc, query, deleteDoc } from "firebase/firestore";
import { bookingCollection, usersCollection } from "../../../firebase";
import { useUser } from "../../../contexts/User";
import { MdCancel } from "react-icons/md";


export interface Booking {
  date: string;
  start_time: string;
  end_time: string;
  user_id: string;
  type: string;
  price: number;
  id: string;
  firstName: string;
  surname: string;
  companyName: string;
  flexible_hours?: number;
  payment_id?: string;
}

const getMonthName = (month: number) =>
  [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][month];

interface Props {
  logged: boolean;
}

const BookingCalendar = ({ logged }: Props) => {
  const { openModal } = useModal();

  const { user } = useUser();

  const [currentMonthBookings, setCurrentMonthBookings] = useState<Booking[]>(
    []
  );

  const [employees, setEmployees] = useState<any[]>([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState('');
  const [selectedEmployeeLevel, setSelectedEmployeeLevel] = useState('');

  const getEmployees = async () => {
    const querySnapshot = await getDocs(
      query(
        usersCollection,
        where("role", "==", 'employee'),
      )
    );

    const tmpEmployees: any[] = [];
    querySnapshot.forEach(el => tmpEmployees.push({...el.data(), id: el.id}));
    setSelectedEmployeeId(tmpEmployees[0].id);
    setSelectedEmployeeLevel(tmpEmployees[0].level);
    setEmployees(tmpEmployees)
  }

  useEffect(() => {
    getEmployees()
  }, []);

  useEffect(() => {
    if(selectedEmployeeId){
      const level = employees.find(el => el.id === selectedEmployeeId).level;
      if(level){
        setSelectedEmployeeLevel(level);
      }
    }
  }, [selectedEmployeeId]);

  const [monthRange, setMonthRange] = useState({
    start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    end: new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0,
      23,
      59
    ),
  });

  const isDayAvailable = (day: Date) => {
    if (
      (day.getMonth() < new Date().getMonth() &&
        day.getFullYear() === new Date().getFullYear()) ||
      day.getFullYear() < new Date().getFullYear() ||
      (day.getFullYear() === new Date().getFullYear() &&
        day.getMonth() === new Date().getMonth() &&
        day.getDate() < new Date().getDate())
    ) {
      return false;
    }

    if (
      day.getFullYear() === new Date().getFullYear() &&
      day.getMonth() === new Date().getMonth() &&
      day.getDate() === new Date().getDate()
    ) {
      return false;
    }

    const today = new Date();
    if (
      (today.getDate() === day.getDate() - 1 || day.getDate() === 1) &&
      today.getHours() >= 16
    ) {
      return false;
    }

    return true;
  };

  const [days, setDays] = useState<Date[]>([]);

  const getBookingsForMonth = async () => {
    const querySnapshot = await getDocs(
      query(
        bookingCollection,
        where("datetype", ">", monthRange.start),
        where("datetype", "<", monthRange.end),
        where('selectedEmployeeId', '==', selectedEmployeeId)
      )
    );

    setCurrentMonthBookings(
      querySnapshot.docs.map(
        (booking) =>
          ({
            ...booking.data(),
            id: booking.id,
          } as Booking)
      )
    );
  };

  useEffect(() => {
    const tmpDays = [];

    if (monthRange.start.getDay() !== 1) {
      let dayOfFirstMonth = monthRange.start.getDay();
      if (dayOfFirstMonth === 0) {
        dayOfFirstMonth = 7;
      }

      const lastDayOfPrevMonth = new Date(
        monthRange.start.getFullYear(),
        monthRange.start.getMonth(),
        0
      ).getDate();

      for (
        let i = lastDayOfPrevMonth - dayOfFirstMonth + 2;
        i <= lastDayOfPrevMonth;
        i++
      ) {
        tmpDays.push(
          new Date(
            monthRange.start.getFullYear(),
            monthRange.start.getMonth() - 1,
            i
          )
        );
      }
    }

    for (let i = 1; i <= monthRange.end.getDate(); i++) {
      tmpDays.push(
        new Date(monthRange.start.getFullYear(), monthRange.start.getMonth(), i)
      );
    }

    setDays(tmpDays);

    getBookingsForMonth();
  }, [monthRange, selectedEmployeeId]);

  const getEventsForDate = (date: Date) => {
    const month =
      date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

    return currentMonthBookings.filter(
      (el) => el.date === `${monthRange.start.getFullYear()}-${month}-${day}`
    );
  };

  
  const getExcludedForDate = (date: Date) => {
    const month =
      date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

    return currentMonthBookings.filter(
      (el) => el.date === `${monthRange.start.getFullYear()}-${month}-${day}` && el.type === 'excluded'
    );
  };


  const setPrevMonth = () => {
    setMonthRange((prev) => ({
      start: new Date(prev.start.getFullYear(), prev.start.getMonth() - 1, 1),
      end: new Date(
        monthRange.end.getFullYear(),
        monthRange.end.getMonth(),
        0,
        23,
        59
      ),
    }));
  };

  const setNextMonth = () => {
    setMonthRange((prev) => ({
      start: new Date(prev.start.getFullYear(), prev.start.getMonth() + 1, 1),
      end: new Date(prev.end.getFullYear(), prev.end.getMonth() + 2, 0, 23, 59),
    }));
  };

  const excludeAllDay = async (e: any, date: Date) => {
    e.stopPropagation();

    for(let i = 7; i < 21; i++){

      const hour = i;

      const fullStartHour = (hour < 10 ? "0" + hour : hour) + ":00";
      const fullEndHour = (hour + 1 < 10 ? "0" + (hour + 1) : hour + 1) + ":00";
  
      const bookDate = new Date(date);
      const bookYear = bookDate.getFullYear();
      const bookMonth = bookDate.getMonth() + 1;
      const bookDay = bookDate.getDate();
  
      const book = {
        date: `${bookYear}-${bookMonth < 10 ? "0" + bookMonth : bookMonth}-${
          bookDay < 10 ? "0" + bookDay : bookDay
        }`,
        start_time: fullStartHour,
        end_time: fullEndHour,
        selectedEmployeeId
      };

  
      await addDoc(bookingCollection, {
        ...book,
        datetype: new Date(`${date.toDateString()} ${hour < 9 ? "0" + hour : hour}:00`),
        user_id: user?.id,
        type: "excluded",
        price: 0,
        payment_id: "",
      });
    }

    window.location.reload();
  }

  const unDoExcludedDay = async (e: any, date: Date) => {
    e.stopPropagation();

    const bookDate = new Date(date);
    const bookYear = bookDate.getFullYear();
    const bookMonth = bookDate.getMonth() + 1;
    const bookDay = bookDate.getDate();

    const q = query(bookingCollection, where('type', '==', 'excluded'), where('date', '==', `${bookYear}-${bookMonth < 10 ? "0" + bookMonth : bookMonth}-${
          bookDay < 10 ? "0" + bookDay : bookDay
        }`), where('selectedEmployeeId', '==', selectedEmployeeId));
    const querySnapshot = await getDocs(q);

    for(const doc of querySnapshot.docs){
      await deleteDoc(doc.ref);
    }
    
    window.location.reload();
  }

  return (
    <div>
      <MonthName>
        {getMonthName(monthRange.start.getMonth())}{" "}
        {monthRange.start.getFullYear()}
      </MonthName>
      <div>
        <EmployeeSelect onChange={e => setSelectedEmployeeId(e.target.value)}>
          {employees.map(el => <option value={el.id}>{el.firstName} {el.surname}</option>)}
        </EmployeeSelect>
      </div>
      <MonthsButtonsWrapper>
        <Button bgColor="#e0e8f6" color="#000" onClick={() => setPrevMonth()}>
          Prev month
        </Button>
        <Button bgColor="#e0e8f6" color="#000" onClick={() => setNextMonth()}>
          Next month
        </Button>
      </MonthsButtonsWrapper>
      <CalendarWrapper>
        <WeekDaysWrapper>
          <li>Mon</li>
          <li>Tue</li>
          <li>Wed</li>
          <li>Thu</li>
          <li>Fri</li>
          <li>Sat</li>
          <li>Sun</li>
        </WeekDaysWrapper>
        <CalendarDaysWrapper>
          {days.map((day) =>
            isDayAvailable(day) ? (
              <DayWrapper
                onClick={(e) =>
                  openModal("booking", {
                    date: day,
                    bookings: getEventsForDate(day),
                    logged,
                    selectedEmployeeId,
                    selectedEmployeeLevel

                  })
                }
              >
                <DayOfMonth>{day.getDate()}</DayOfMonth>
                <AvailableHoursWrapper>
                  <AvailableHoursData>
                    <AvailableHoursIcon /> {14 - getEventsForDate(day).length}{" "}
                    hrs
                  </AvailableHoursData>{" "}
                </AvailableHoursWrapper>
                {getEventsForDate(day).length === 0 && user?.id === "GFLkhtp4KNM0l8AXoEee" && <ExcludeButton onClick={e => excludeAllDay(e, day)}><MdCancel /></ExcludeButton>}
                {getExcludedForDate(day).length === 14 && user?.id === "GFLkhtp4KNM0l8AXoEee" && <IncludeButton onClick={(e) => unDoExcludedDay(e, day)}>Include</IncludeButton>}
              </DayWrapper>
            ) : (
              <UnavailableDayWrapper>{day.getDate()}</UnavailableDayWrapper>
            )
          )}
        </CalendarDaysWrapper>
      </CalendarWrapper>
    </div>
  );
};

export default BookingCalendar;
